import React, { useState } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { FaRegEyeSlash } from "react-icons/fa";
import { MdOutlineVisibility } from "react-icons/md";
import axios from "axios";
import close from "../../../Assests/main/table/Close.svg";
import "../../../CSS/Login.css";
import Loader from "../../../Components/Loader.js";
import { toast } from "react-toastify";
import { base_url } from "../../../Api/api.js";

const Login = ({ SetIsogin }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [passwordvisible, setPasswordvisible] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async (data) => {
    try {
      setLoading(true);
      const response = await axios.post(`${base_url}/auth/login`, {
        username: data.username,
        password: data.password,
      });

      const responseData = response.data;

      if (responseData.data.access_token) {
        localStorage.setItem("token", responseData.data.access_token);
        localStorage.setItem("user", JSON.stringify(responseData.data.user));
        toast.success("login Sucessful");
        SetIsogin(true);
        navigate("/website");
      } else {
        setErrorMessage(responseData.message);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };
  const pvisibel = () => {
    setPasswordvisible(false);
  };
  const pinvisible = () => {
    setPasswordvisible(true);
  };

  return (
    <div>
      <div className="login_container">
        <div className="container_first">
          <div className="welcome">Welcome Back!👋</div>
          <Link to="/">
            <div className="close_icon">
              <img src={close} alt="" />
            </div>
          </Link>
        </div>
        <div className="login_act">Login to your Account</div>

        <form onSubmit={handleSubmit(handleLogin)}>
          <div className="login_middle">
            <label className="Email">Enter Email*</label>
            <input
              className="email-input"
              type="text"
              placeholder="Enter Email"
              {...register("username", {
                required: "Email is required",
                pattern: {
                  value:
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: "Please enter a valid email",
                },
              })}
            />
            {errors.username && (
              <p style={{ color: "red" }}>{errors.username.message}</p>
            )}

            <div>
              <label className="Password">Password*</label>
              <div className="password-input">
                <input
                  style={{ marginBottom: "20px" }}
                  className="pinput"
                  type={passwordvisible ? "text" : "password"}
                  placeholder="Enter Password"
                  {...register("password", {
                    required: "Password is required",
                    minLength: {
                      value: 8,
                      message: "must be 8 charcter",
                    },
                  })}
                />
                <div className="icon1">
                  {passwordvisible ? (
                    <MdOutlineVisibility onClick={pvisibel} />
                  ) : (
                    <FaRegEyeSlash onClick={pinvisible} />
                  )}
                </div>
              </div>
              {errors.password && (
                <p style={{ color: "red" }}>{errors.password.message}</p>
              )}
            </div>

            {/* <div className="forget_password">
            <div className="forget_password1">Forget Password ?</div>
            <div>
              <input type="checkbox" id="remember" name="remember" />
              <label>Remember me</label>
            </div>
          </div> */}
          </div>

          <div>
            <button className="login_btn" type="submit">
              {loading ? <Loader /> : "Login"}
            </button>
            <div className="last">
              Do not have an account?{" "}
              <Link to="/signup">
                <span className="span">Sign Up</span>
              </Link>
            </div>
          </div>

          {errorMessage && <p>{errorMessage}</p>}
        </form>
      </div>
    </div>
  );
};

export default Login;
