import React from "react";
import "../../../CSS/WebsiteMiddle.css";
import "../../../CSS/latest.css";
import eye from "../../../Assests/main/eye.svg";
import intopros from "../../../Assests/main/table/intopros.svg";
import notepad2 from "../../../Assests/main/notepad2.svg";
import pointer from "../../../Assests/main/pointer.svg";
import { Link, useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";

import Graph from "./Graph/Graph";
import SslInfo from "./SslInfo/SslInfo";
import History from "./History/History";
import SideBar from "../Components/SideBar";

const ReportDetails = () => {
  const { id } = useParams();
  console.log("first", id);
  console.log(id);
  const location = useLocation();
  const url = location.pathname;
  const lastPathSegment = url.substring(url.lastIndexOf("/") + 1);

  const checkACtive = (val) => {
    if (lastPathSegment == "website" && val == "website") {
      return "active";
    } else if (lastPathSegment == "checknow" && val == "checknow") {
      return "active";
    } else if (lastPathSegment == "reports" && val == "reports") {
      return "active";
    } else {
      return "";
    }
  };

  const user = JSON.parse(localStorage.getItem("user"));
  return (
    <div>
      <div className="">
        <div className="page_content">
          <div className="page_table">
            <Graph id={id} />
            <SslInfo id={id} />
            <History id={id} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportDetails;
