import React, { useEffect, useState } from "react";
import "../../../../CSS/History.css";
import axios from "axios";
import dayjs from "dayjs";
import { base_url } from "../../../../Api/api";

const History = ({ id }) => {
  const [data, setData] = useState([""]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${base_url}/url/${id}/status`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = response.data.urlHistoryList;
      console.log(data);
      setData(data);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  return (
    <>
      <div className="History-main">
        <div className="History-top-section">History</div>
        <div className="w-full overflow-auto">
          <table className="customers">
            <tr>
              <th>Website URL</th>
              <th>Last Checked</th>
              <th>Up Time</th>
              <th>Website Status</th>
              <th>SSl Status</th>
            </tr>
            {/* dayjs(e.updateAt).format("YYYY-MM-DD HH:mm:ss") */}
            {data?.map((e) => (
              <tr>
                <td>{e?.url?.url}</td>
                <td>{dayjs(e.checkTimestamp).format("YYYY-MM-DD")}</td>
                <td>{e?.url?.uptime}</td>
                <td>
                  <button
                    className={`${e.status === "ok" ? "active1" : "inactive2"}`}
                  >
                    {e.status === "ok" ? "Active" : "Inactive"}
                  </button>
                </td>
                <td>
                  <button
                    className={`${e.sslStatus ? "active1" : "inactive2"}`}
                  >
                    {e.sslStatus ? "Active" : "Inactive"}
                  </button>
                </td>
              </tr>
            ))}
          </table>
        </div>
      </div>
    </>
  );
};

export default History;
