import { useState } from "react";
import "../../../../../CSS/StatusField.css";
import pointer from "../../../../../Assests/main/pointer.svg";
import { useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import { base_url } from "../../../../../Api/api.js";



const StatusField = () => {
  const [data, setData] = useState([""]);

  useEffect(() => {
    fetchdata();
  }, []);

  const fetchdata = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${base_url}/url`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(response);
      const data = response.data.urls;
      console.log(data);
      setData(data);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div
      style={{ display: "grid", gridTemplateColumns: "auto auto", gap: "15px" }}
    >
      {data.map((e) => {
        return (
          <Link to={`/reportdetails/${e.id}`}>
            <div class="mt-2">
              <div className="modal-container2">
                <div className="modal-inner-top">
                  <div className="pointericon">
                    <img src={pointer} alt="" />
                  </div>
                  <p className="website_name">{e.url}</p>
                </div>

                <div className="web1ssl">
                  <div className="Website_Status">
                      Website Status : 
                    <button
                      className={`${
                        e.status === "ok" ? "active_button" : "inactive_button"
                      }`}
                    >
                      {e.status === "ok" ? "Active" : "Inactive"}
                    </button>
                  </div>
                  <div className="SSl_Status">
                    <div></div>
                    SSL Status:
                    <button
                      className={`${
                        e.sslStatus ? "active_button" : "inactive_button"
                      }`}
                    >
                      {e.sslStatus ? "Active" : "Inactive"}
                    </button>
                  </div>
                </div>
                <div className="last_checkedmain">
                  <div className="last_checked">Last Checked:</div>
                  <div className="last-check_data">
                    {dayjs(e.updateAt).format("YYYY-MM-DD HH:mm:ss")}
                  </div>
                </div>
              </div>{" "}
            </div>
          </Link>
        );
      })}
    </div>
  );
};

export default StatusField;
