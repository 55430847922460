import React from "react";
import Table from "./Table/Table";

const Websites = () => {
  return (
    <div className="w-full">
      <Table />
    </div>
  );
};

export default Websites;
