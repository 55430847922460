import React, { useState, useEffect } from "react";
import "../../../../CSS/Table.css";
import group from "../../../../Assests/main/table/Group.svg";
import button from "../../../../Assests/main/table/Button.svg";
import frame from "../../../../Assests/main/table/Frame.svg";
import AddnewModal from "../Modal/AddnewModal";
import axios from "axios";
import EditModal from "../Modal/EditModal";
import DeleteConfModal from "../Modal/DeleteConfModal";
import { toast } from "react-toastify";
import { base_url } from "../../../../Api/api";

const Table = () => {
  const [openmodal, setOpenmodal] = useState(false);
  const [editopenmodal, seteditOpenmodal] = useState(false);
  const [deleteopenmodal, setDeleteopenmodal] = useState(false);
  const [data, setData] = useState([]);
  const [setErrorMessage] = useState("");
  const [confirmdelete, setConfirmdelete] = useState("");
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Fetch data from the API
    fetchData();
  }, []);

  const fetchData = async (search) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${base_url}/url?search=${search ?? ""}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const responseData = response.data;
      console.log(responseData);

      if (responseData.urls) {
        setData(responseData.urls); // Update the state with the received data
      } else {
        setErrorMessage(responseData.message);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const deleteurl = async (id) => {
    setConfirmdelete(id);
    setDeleteopenmodal(true);
  };

  const urlDeletion = async (id) => {
    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      const response = await axios.delete(`${base_url}/url/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      fetchData();
      const responseData = response.data;
      if (responseData.urls) {
        setData(responseData.urls); // Update the state with the received data
        setDeleteopenmodal(false);
      } else {
        setErrorMessage(responseData.message);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
      setDeleteopenmodal(false);
    }
    toast.error("Url deleted Successfully");
  };
  const search1 = (e) => {
    setSearch(e.target.value);
  };
  const submithandler = (e) => {
    e.preventDefault();
    fetchData(search);
  };

  return (
    <div className="">
      <div className="add_website">Add Websites</div>
      <div className="table_second">
        <div className="inputr">
          <form onSubmit={submithandler}>
            <input
              className="inputfield"
              type="text"
              value={search}
              onChange={search1}
              placeholder="Search by URL"
            />{" "}
          </form>
          <div className="search_button">
            <img onClick={submithandler} src={frame} alt="" />
          </div>
        </div>

        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 -700 rounded"
          onClick={() => {
            setOpenmodal(true);
          }}
        >
          Add New
        </button>
      </div>

      <div className="w-full overflow-auto">
        <table className="customers">
          <thead>
            <tr>
              <th>Website URL</th>
              <th>Email</th>
              <th>Phone Number</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {data.map((e, index) => (
              <tr key={e.id}>
                <td>{e.url}</td>
                <td>{e.email}</td>
                <td>{e.contactNumber}</td>
                <td className="action">
                  <img src={group} onClick={() => deleteurl(e.id)} alt="" />
                  <img
                    src={button}
                    onClick={() => {
                      setConfirmdelete(e.id);
                      seteditOpenmodal(true);
                    }}
                    alt=""
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {openmodal && (
        <AddnewModal closeModal={setOpenmodal} fetchData={fetchData} />
      )}
      {editopenmodal && (
        <EditModal
          closeModal={seteditOpenmodal}
          id={confirmdelete}
          fetchData={fetchData}
        />
      )}
      {deleteopenmodal && (
        <DeleteConfModal
          closeModal={setDeleteopenmodal}
          id={confirmdelete}
          urlDeletion={urlDeletion}
          loading={loading}
        />
      )}
    </div>
  );
};

export default Table;
