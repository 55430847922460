import React, { useState } from "react";
import frame from "../../../Assests/main/table/Frame.svg";
import "../../../CSS/TableCheckNow.css";
import axios from "axios";
import { useEffect } from "react";
import Loader from "../../../Components/Loader";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import AddnewModal from "../Websites/Modal/AddnewModal";
import EditModal from "../Websites/Modal/EditModal";
import DeleteConfModal from "../Websites/Modal/DeleteConfModal";
import { base_url } from "../../../Api/api.js";

const TableCheckNow = () => {
  const [data, setData] = useState([]);
  const [openmodal, setOpenmodal] = useState(false);
  const [editopenmodal, seteditOpenmodal] = useState(false);
  const [deleteopenmodal, setDeleteopenmodal] = useState(false);
  const [isHeaderCheckboxSelected, setHeaderCheckboxSelected] = useState(false);
  const [checkboxes, setCheckboxes] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [confirmdelete, setConfirmdelete] = useState("");
  const [search, setSearch] = useState("");

  useEffect(() => {
    FetchDataUrl();
  }, []);

  const FetchDataUrl = async (search) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${base_url}/url?search=${search ?? ""}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data.urls;
      setData(data);

      // Initialize the selected state of checkboxes
      const initialCheckboxes = data.map((e) => ({
        id: e.id,
        selected: false,
      }));
      setCheckboxes(initialCheckboxes);
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const deleteurl = async (id) => {
    setConfirmdelete(id);
    setDeleteopenmodal(true);
  };
  const urlDeletion = async (id) => {
    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      const response = await axios.delete(`${base_url}/url/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      FetchDataUrl();
      const responseData = response.data;
      if (responseData.urls) {
        setData(responseData.urls); // Update the state with the received data
        setDeleteopenmodal(false);
      } else {
        setErrorMessage(responseData.message);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
      setDeleteopenmodal(false);
    }
    toast.error("Url deleted Successfully");
  };

  const handleHeaderCheckboxChange = () => {
    setHeaderCheckboxSelected(!isHeaderCheckboxSelected);
    console.log("handleheadercheckboxchange");
    // Toggle the selected state of all checkboxes
    const updatedCheckboxes = checkboxes.map((checkbox) => ({
      ...checkbox,
      selected: !isHeaderCheckboxSelected,
    }));

    setCheckboxes(updatedCheckboxes);
  };

  const handleCheckboxChange = (id) => {
    console.log("handlecheckboxchange", checkboxes);

    // Update the selected state of the checkbox with the given id
    const updatedCheckboxes = checkboxes.map((checkbox) => {
      console.log(checkbox);

      return checkbox.id === id
        ? { ...checkbox, selected: !checkbox.selected }
        : checkbox;
    });
    // console.log(updatedCheckboxes);

    setCheckboxes(updatedCheckboxes);
  };

  const checkurlStatus = async () => {
    const urlList = checkboxes
      .filter((checkbox) => checkbox.selected)
      .map((select) => select.id);
    console.log(urlList);
    if (urlList.length < 1) {
      toast.error("Please select at least one Url");
      return;
    }

    try {
      setLoading(true);

      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${base_url}/url/check`,
        {
          urls: [...urlList],
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = response.data.urls;
      console.log(data);
      setData(data);
    } catch (error) {
      console.log("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const search1 = (e) => {
    setSearch(e.target.value);
  };
  const submithandler = (e) => {
    e.preventDefault();
    FetchDataUrl(search);
  };

  return (
    <div className="table_main">
      <div className="add_website">Check Websites Status</div>
      <div className="table_second">
        <div className="inputr">
          <form onSubmit={submithandler}>
            <input
              className="inputfield"
              type="text"
              placeholder="Search by URL"
              value={search}
              onChange={search1}
            />{" "}
          </form>
          <div className="search_button">
            <img src={frame} alt="" />
          </div>
        </div>
        <button
          class="bg-blue-500 hover:bg-blue-700 items-center text-white font-bold py-2 px-4 -700 rounded"
          onClick={checkurlStatus}
        >
          {loading ? <Loader /> : "Check Now"}
        </button>
      </div>
      <div className="w-full overflow-auto">
        <table className="customers">
          <tr>
            <th>
              <input
                type="checkbox"
                checked={isHeaderCheckboxSelected}
                onChange={handleHeaderCheckboxChange}
              />
            </th>
            <th>Website URL</th>
            <th>Last Checked</th>
            <th>Up Time</th>
            <th>Website Status</th>
            <th>SSl Status</th>
            <th>Expires in</th>
            {/* <th>Actions</th> */}
          </tr>

          {data.map((e) => (
            <tr>
              <td>
                <input
                  type="checkbox"
                  checked={
                    checkboxes.find((checkbox) => checkbox.id === e.id)
                      ?.selected
                  }
                  onChange={() => handleCheckboxChange(e.id)}
                />
              </td>
              <td>{e.url}</td>

              <td>{dayjs(e.updateAt).format("YYYY-MM-DD HH:mm:ss")}</td>
              <td>{e.uptime}</td>
              <td>
                <button
                  className={`${
                    e.status === null
                      ? ""
                      : e.status === "ok"
                      ? "active1"
                      : "inactive2"
                  }`}
                >
                  {e.status === null
                    ? "-"
                    : e.status === "ok"
                    ? "Active"
                    : "Inactive"}
                </button>
              </td>
              <td>
                <button
                  className={`${
                    e.sslStatus === null
                      ? ""
                      : e.sslStatus
                      ? "active1"
                      : "inactive2"
                  }`}
                >
                  {e.sslStatus === null
                    ? "-"
                    : e.sslStatus
                    ? "Active"
                    : "Inactive"}
                </button>
                {/* {console.log(e.sslS)} */}
              </td>
              <td>{dayjs(e.validTo).format("YYYY-MM-DD HH:mm:ss")}</td>
              {/* <td className="">
              <div className="action-last">
                {" "}
                <img src={group} onClick={() => deleteurl(e.id)} alt="" />
                <img
                  src={button}
                  onClick={() => {
                    setConfirmdelete(e.id);
                    seteditOpenmodal(true);
                  }}
                  alt=""
                />
              </div>
            </td> */}
            </tr>
          ))}
        </table>

        {openmodal && (
          <AddnewModal closeModal={setOpenmodal} FetchDataUrl={FetchDataUrl} />
        )}
        {editopenmodal && (
          <EditModal
            closeModal={seteditOpenmodal}
            id={confirmdelete}
            FetchDataUrl={FetchDataUrl}
          />
        )}
        {deleteopenmodal && (
          <DeleteConfModal
            closeModal={setDeleteopenmodal}
            loading={loading}
            id={confirmdelete}
            urlDeletion={urlDeletion}
          />
        )}
      </div>
    </div>
  );
};

export default TableCheckNow;
