import React, { useEffect, useState } from "react";
import Logo from "../Assests/Logo.svg";
import "../CSS/Header.css";
import { Link, useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

const Header = () => {
  const location = useLocation();
  const url = location.pathname;
  const lastPathSegment = url.substring(url.lastIndexOf("/") + 1);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const isMobile = useMediaQuery({ maxDeviceWidth: 768 });

  const checkActive = (val) => {
    if (lastPathSegment === "webMonitoring" && val === "webMonitoring") {
      return "active";
    } else if (lastPathSegment === "sslMonitoring" && val === "sslMonitoring") {
      return "active";
    } else if (lastPathSegment === "login" && val === "login") {
      return "active";
    } else {
      return "";
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev);
  };

  return (
    <div>
      <div className={`header ${isMenuOpen ? "active" : ""}`}>
        <div className="left_section">
          <div className="check">
            <Link to="/">
              <img src={Logo} alt="" />
            </Link>
          </div>
        </div>
        {isMobile && (
          <a href="#" className="toggle-button" onClick={toggleMenu}>
            <span className="bar"></span>
            <span className="bar"></span>
            <span className="bar"></span>
          </a>
        )}
        <div
          className={`right_section1 ${
            isMobile && !isMenuOpen ? "hidden" : ""
          }`}
        >
          <Link to="/webMonitoring">
            <div
              className={`${checkActive(
                "webMonitoring"
              )} hover:text-[#1A73E8] `}
            >
              Web Monitoring
            </div>
          </Link>
          <Link to="/sslMonitoring">
            <div
              className={`${checkActive(
                "sslMonitoring"
              )}  hover:text-[#1A73E8]`}
            >
              SSL Monitoring
            </div>
          </Link>
          <div className={`${checkActive("login")} hover:text-[#1A73E8]`}>
            <Link to="/login">Login</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
