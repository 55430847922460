import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import "./App.css";
import Modals from "./PageWrapper/Home/SsslMonitoring/Modals/Modals";
import Home from "./PageWrapper/Home/Home";
import Login from "./PageWrapper/Authentic/Login/Login";
import CheckNow from "./PageWrapper/Dashboard/CheckNow/CheckNow";
import ReportDetails from "./PageWrapper/Dashboard/Reports/ReportDetails";
import Reports from "./PageWrapper/Dashboard/Reports/Reports";
import Websites from "./PageWrapper/Dashboard/Websites/Websites";
import SignUp from "./PageWrapper/Authentic/SignUp/SignUp";
import SslMonitoring from "./PageWrapper/Home/SsslMonitoring/SslMonitoring";
import WebMonitoring from "../src/PageWrapper/Home/WebMonitoring/WebMonitoring";
import AddnewModal from "./PageWrapper/Dashboard/Websites/Modal/AddnewModal";
import ProtectedRoute from "./Components/ProtectedRoute";
import { ToastContainer } from "react-toastify";
import Header2 from "./Header/Header2";
import Header from "./Header/Header";
import MyProfile from "./PageWrapper/Dashboard/Profile/MyProfile";
import { useState, useEffect } from "react";
import Footer from "./Footer/Footer";
import EditProfile from "./PageWrapper/Dashboard/Profile/EditProfile";
import { NotFound } from "./Components/NotFound";

function App() {
  const [islogin, SetIsogin] = useState(false);

  const token = localStorage.getItem("token");

  console.log(islogin);

  useEffect(() => {
    if (token) {
      SetIsogin(true);
    }
  }, [token]);

  return (
    <div className="App">
      <ToastContainer
        position="top-center"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/* Same as */}
      <Router>
        {islogin && <Header2 SetIsogin={SetIsogin} />}
        {!islogin && <Header />}
        <Routes>
          <Route
            path="/"
            element={islogin ? <Navigate to="/website" replace /> : <Home />}
          />
          <Route
            path="webMonitoring"
            element={
              islogin ? <Navigate to="/website" replace /> : <WebMonitoring />
            }
          />
          <Route
            path="sslMonitoring"
            element={
              islogin ? <Navigate to="/website" replace /> : <SslMonitoring />
            }
          />
          <Route
            path="/login"
            element={
              islogin ? (
                <Navigate to="/website" replace />
              ) : (
                <Login SetIsogin={SetIsogin} />
              )
            }
          />
          <Route path="signup" element={<SignUp />} />
          <Route path="modals" element={<Modals />} />

          <Route
            path="checknow"
            element={
              <ProtectedRoute>
                <CheckNow />
              </ProtectedRoute>
            }
          />
          <Route
            path="reports"
            element={
              <ProtectedRoute>
                <Reports />
              </ProtectedRoute>
            }
          />
          <Route
            path="reportdetails/:id"
            element={
              <ProtectedRoute>
                <ReportDetails />
              </ProtectedRoute>
            }
          />
          <Route
            path="addnew"
            element={
              <ProtectedRoute>
                {" "}
                <AddnewModal />
              </ProtectedRoute>
            }
          />

          <Route
            path="/website"
            element={
              <ProtectedRoute>
                {" "}
                <Websites />
              </ProtectedRoute>
            }
          />
          <Route
            path="/myprofile"
            element={
              <ProtectedRoute>
                {" "}
                <MyProfile />
              </ProtectedRoute>
            }
          />
          <Route
            path="/editprofile"
            element={
              <ProtectedRoute>
                {" "}
                <EditProfile />
              </ProtectedRoute>
            }
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>

      <Footer />
    </div>
  );
}

export default App;
