import React from 'react'
// import '../../../CSS/ReportModals.css'

import { Link } from 'react-router-dom'
import StatusField from '../../Home/SsslMonitoring/Modals/Components/StatusField'
import '../../../CSS/ReportMiddle2.css'

const ReportMiddle = () => {
  return (
    <div className='w-full overflow-auto'>
    <div><StatusField/></div>
    </div>
  )
}

export default ReportMiddle