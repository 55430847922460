import React, { useState } from "react";
import Logo from "../Assests/Logo.svg";
import { Link, useNavigate } from "react-router-dom";
import logout from "../Assests/main/Logout.svg";
import "../CSS/Header2.css";
import { useMediaQuery } from "react-responsive";
import SidebarHamburger from "../PageWrapper/Dashboard/Components/SidebarHamburger";

const Header2 = ({ SetIsogin }) => {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const isMobile = useMediaQuery({ maxDeviceWidth: 768 });
  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev);
  };
  // const closeMenu = () => {
  //   setIsMenuOpen(false);
  // };

  return (
    <div>
      <div className="header">
        <div className="left_section">
          <div className="check">
            <Link to="/">
              <img src={Logo} alt="" />
            </Link>
          </div>
        </div>
        {isMobile && (
          <a href="#" className="toggle-button2" onClick={toggleMenu}>
            <span className={`bar ${isMenuOpen ? "active" : ""}`}></span>
            <span className={`bar ${isMenuOpen ? "active" : ""}`}></span>
            <span className={`bar ${isMenuOpen ? "active" : ""}`}></span>
          </a>
        )}
        <div style={{ cursor: "pointer" }} className="right_section2">
          <div
            className="login hover:text-[#1A73E8]"
            onClick={() => {
              localStorage.clear();
              SetIsogin(false);
              navigate("/");
            }}
          >
            Logout
            <img src={logout} alt="" />
          </div>
        </div>
      </div>
      {isMobile && isMenuOpen && (
        <SidebarHamburger closeMenu={toggleMenu} SetIsogin={SetIsogin} />
      )}{" "}
      {/* Render the Sidebar component when menu is open */}
    </div>
  );
};

export default Header2;
