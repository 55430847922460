import React, { useEffect, useState } from "react";
import "../../../../CSS/History.css";
import axios from "axios";
import dayjs from "dayjs";
import { base_url } from "../../../../Api/api";

const SslInfo = ({ id }) => {
  const [sslData, setSslData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${base_url}/url/${id}/status`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const data = response.data.urlHistoryList;
      console.log(data);

      // Check if data is an array before setting the state
      if (Array.isArray(data)) {
        setSslData(data);
      } else {
        // Handle the case where data is not an array, e.g., when it's undefined or some unexpected format
        console.error("Error: Invalid data format received");
        setSslData([]); // Initialize the state with an empty array to avoid further issues
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="">
      <div className="ssl-main">
        <div className="ssl-top-section">SSL Info</div>
        <div className="w-full overflow-auto">
          <table className="customers">
            <tr>
              <th>Expires in</th>
              <th>Valid From</th>
              <th>Valid To</th>
              <th>Valid For</th>
            </tr>
            {sslData.length > 0 ? (
              // If sslData has elements, render the table row
              <tr>
                <td>{sslData[0]?.sslExpiryDate}days</td>
                <td>
                  {dayjs(sslData[0]?.url?.validFrom).format(
                    "YYYY-MM-DD HH:mm:ss"
                  )}
                </td>
                <td>
                  {dayjs(sslData[0]?.url?.validTo).format(
                    "YYYY-MM-DD HH:mm:ss"
                  )}
                </td>
                <td>{sslData[0]?.url?.email}</td>
              </tr>
            ) : (
              // Otherwise, display a message indicating that data is loading
              <tr>
                <td colSpan="4">Loading...</td>
              </tr>
            )}
          </table>
        </div>
      </div>
    </div>
  );
};

export default SslInfo;
