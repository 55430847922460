import React from "react";
import "../../../CSS/WebsiteMiddle.css";
import "../../../CSS/latest.css";
import ReportMiddle from "./ReportMiddle";

const CheckNowMiddle = () => {
  return (
    <div>
      <ReportMiddle />
    </div>
  );
};

export default CheckNowMiddle;
