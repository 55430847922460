import React from "react";
import "../../../CSS/WebsiteMiddle.css";

import eye from "../../../Assests/main/eye.svg";
import intopros from "../../../Assests/main/table/intopros.svg";
import notepad2 from "../../../Assests/main/notepad2.svg";
import pointer from "../../../Assests/main/pointer.svg";

import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
const SideBar = () => {
  const location = useLocation();
  const url = location.pathname;
  const lastPathSegment = url.substring(url.lastIndexOf("/") + 1);
  const checkACtive = (val) => {
    if (lastPathSegment === "website" && val === "website") {
      return "active";
    } else if (lastPathSegment === "checknow" && val === "checknow") {
      return "active";
    } else if (lastPathSegment === "reports" && val === "reports") {
      return "active";
    } else {
      return "";
    }
  };

  const user = JSON.parse(localStorage.getItem("user"));
  return (
    <>
      <div className="page_sidebar">
        <div className="sidebar-top">
          <Link to={"/website"}>
            {" "}
            <div className={`sidebar-link ${checkACtive("website")}`}>
              <img src={pointer} alt="" />
              Websites
            </div>
          </Link>
          <Link to={"/checknow"}>
            {" "}
            <div className={`sidebar-link ${checkACtive("checknow")}`}>
              <img src={eye} alt="" />
              Check Now
            </div>
          </Link>
          <Link to={"/reports"}>
            <div className={`sidebar-link ${checkACtive("reports")}`}>
              <img src={notepad2} alt="" />
              Reports
            </div>
          </Link>
        </div>
        <Link to={"/myprofile"}>
          <div className="sidebar-bottom">
            <img src={intopros} alt="" />
            <div className="">
              <div class="">{user.username}</div>
              <div className="email">{user.email}</div>
            </div>
          </div>
        </Link>
      </div>
    </>
  );
};
export default SideBar;
