import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import axios from "axios";
import dayjs from "dayjs";
import { base_url } from "../../../../Api/api";

function Graph({ id }) {
  const [statusInfo, setStatusInfo] = useState([]);
  Chart.register(CategoryScale);

  const [chartData, setChartData] = useState([-1, 0, 1]);
  const [fetchdata, setFetchdata] = useState([""]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${base_url}/url/${id}/status`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const graphResult = response?.data.urlGraphdata.map((url) => {
        return dayjs(url.date).format("YYYY-MM-DD");
      });
      response?.data.urlGraphdata.map((url) => {
        if (url.status?.toUpperCase() === "OK") {
          setStatusInfo((preval) => [...preval, 1]);
        }
        if (url.status?.toUpperCase() === "ERROR") {
          setStatusInfo((preval) => [...preval, -1]);
        }
        return url.count;
      });
      console.log(response);
      const fetchdata = response.data.urlGraphdata.date;
      setChartData(response.data);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const data = {
    labels: chartData?.urlGraphdata?.map((data) =>
      dayjs(data.date).format("YYYY-MM-DD")
    ),
    datasets: [
      {
        label: chartData.url,
        data: statusInfo,
        fill: false,
        pointBackgroundColor: function (context) {
          var index = context.dataIndex;
          var value = context.dataset.data[index];
          return value >= context.dataset.data[index - 1];
        },
        borderColor: "silver",
        tension: 0.2,
      },
    ],
  };

  const options = {
    responsive: true,
    bezierCurve: true,
    scales: {
      y: {
        min: -1.5,
        max: 1.5,
        ticks: {
          beginAtZero: false,
          min: 0,
          stepSize: 1.5,
        },
      },
    },
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: false,
        text: "www.giripradip.com.np",
      },
    },
  };
  return <Line data={data} options={options} />;
}
export default Graph;
