import React, { useState } from "react";
import { useNavigate } from "react-router";
import { useForm } from "react-hook-form";
import "../../../CSS/WebMonitoring.css";
import axios from "axios";
import Loader from "../../../Components/Loader.js";
import { base_url } from "../../../Api/api.js";
import Modals from "../SsslMonitoring/Modals/Modals.jsx";
import { toast } from "react-toastify";

const SsslMonitoring = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [responseData, setResponseData] = useState(null);

  const navigate = useNavigate();

  const Postdata = async (reqData) => {
    if (selectedOption === "Checknow") {
      try {
        setLoading(true);
        const token = localStorage.getItem("token");
        const response = await axios.post(
          `${base_url}/url/ssl-monitoring`,
          {
            url: reqData.url,
            email: reqData.email,
            contactNumber: parseInt(reqData.contactNumber),
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = response?.data?.data;
        setResponseData(data);
        console.log(data);
        setOpenModal(true);
      } catch (error) {
        console.error("Error:", error);
      }
      setLoading(false);
      return;
    }
    if (selectedOption === "value") {
      navigate("/login");
      return;
    }
    toast.error("Check at least one Checkbox");
  };

  return (
    <div>
      <div className="container-form">
        <div className="btn" style={{ textAlign: "left" }}>
          <button className="bg-[#E8F1FD] text-blue-600 hover:bg-blue-700 hover:text-[white] font-bold py-2 px-4 -700 rounded">
            Free Monitoring
          </button>
        </div>

        <div className="form">
          <form onSubmit={handleSubmit(Postdata)}>
            <div className="label-container">
              <div className="label">WebSite URL:</div>
              <input
                style={{ width: "100%" }}
                type="text"
                className="input_btn"
                placeholder="www.example.com"
                name="url"
                {...register("url", {
                  required: "URL is required",
                  pattern: {
                    value:
                      /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/,
                    message: "Please enter a valid URL",
                  },
                })}
              />
              {errors.url && (
                <p style={{ color: "red" }}>{errors.url.message}</p>
              )}
            </div>

            <div className="label-container">
              <div className="label">Email:</div>
              <input
                style={{ width: "100%" }}
                type="text"
                className="input_btn"
                placeholder="Enter Email"
                name="email"
                {...register("email", {
                  required: "Email is required",
                  pattern: {
                    value:
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: "Please enter a valid email",
                  },
                })}
              />
              {errors.email && (
                <p style={{ color: "red" }}>{errors.email.message}</p>
              )}
            </div>

            <div className="label-container">
              <label className="label">Phone Number:</label>
              <input
                style={{ marginTop: "15px" }}
                type="number"
                className="input_btn"
                placeholder="Enter Phone Number"
                name="contactNumber"
                // onChange={handleUsercontactNumberChange}
                {...register("contactNumber", {
                  required: true,
                })}
              />
              {errors.contactNumber && (
                <p style={{ color: "red" }}>This field is required</p>
              )}
            </div>

            <div className="radio_btn">
              <div className="radio-container" style={{ marginBottom: 20 }}>
                <label>
                  <input
                    type="radio"
                    value="Checknow"
                    name="gender"
                    checked={selectedOption === "Checknow"}
                    onChange={() => setSelectedOption("Checknow")}
                  />{" "}
                  Check Now
                </label>
              </div>
              <div className="radio-container">
                <label>
                  <input
                    type="radio"
                    value="value"
                    name="checkbox"
                    checked={selectedOption === "value"}
                    onChange={() => setSelectedOption("value")}
                  />{" "}
                  Notify when the Website is down
                </label>
              </div>
              <div style={{ marginTop: "15px" }} className="statnow">
                <button className="start-button bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                  {loading ? <Loader /> : "Start Now"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      {openModal && <Modals closeModal={setOpenModal} data={responseData} />}
    </div>
  );
};

export default SsslMonitoring;
