import React from "react";
import "../../../CSS/MyProfile.css";
import { Link } from "react-router-dom";
import { FaRegEyeSlash } from "react-icons/fa";
import { MdOutlineVisibility } from "react-icons/md";
import { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { base_url } from "../../../Api/api.js";

const EditProfile = () => {
  const [passwordvisible, setPasswordvisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [data, setData] = useState([]);
  const [input, setInput] = useState({
    firstName: "",
    lastName: "",
    email: "",
    contactNumber: "",
  });

  useEffect(() => {
    fetchdata();
  }, []);

  const fetchdata = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${base_url}/users/my-details`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const {
        firstName,
        lastName,
        user: { email },
      } = response.data.data;
      console.log(data);
      setInput({
        firstName,
        lastName,
        email,
      });
    } catch (e) {
      console.log(e.response);
    }
  };

  const handleEdit = async (e) => {
    e.preventDefault();

    // console.log(input);
    try {
      // setLoading(true);
      const token = localStorage.getItem("token");
      const response = await axios.patch(
        `${base_url}/users/my-details`,
        {
          firstName: input.firstName,
          lastName: input.lastName,
          email: input.email,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data;

      // fetchData();
    } catch (error) {
      console.error("Error:", error);
    } finally {
      toast.success("Profile Updated successfully");
      // setLoading(false);
    }

    //for password
    try {
      // setLoading(true);
      const token = localStorage.getItem("token");
      const response = await axios.patch(
        `${base_url}/users/me`,
        {
          firstName: input.firstName,
          lastName: input.lastName,
          email: input.email,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);

      const data = response.data;
      // fetchData();

      if (data.urls) {
        setData(data.urls);
      } else {
        setErrorMessage(data.message);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      toast.success("Profile Updated successfully");
  
    }
  };

  const changeHandler = (e) => {
    const { name, value } = e.target;
    setInput({ ...input, [name]: value });
  };

  const pvisibel = () => {
    setPasswordvisible(false);
  };
  const pinvisible = () => {
    setPasswordvisible(true);
  };
  return (
    <div>
      <div className="profile-main p-3">
        <div className="profile-top">
          <div className="profile-title font-black">My Profile</div>
        </div>
        <div className="profile-bottom"></div>

        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
          <div className="sm:col-span-3">
            <label
              htmlFor="first-name"
              className="label block text-sm font-medium leading-6 text-gray-900"
            >
              First Name
            </label>
            <div className="mt-2">
              <input
                onChange={changeHandler}
                type="text"
                name="firstName"
                id="first-name"
                value={input.firstName}
                className="block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div className="sm:col-span-3">
            <label
              htmlFor="full-name"
              className="label block text-sm font-medium leading-6 text-gray-900"
            >
              Last Name
            </label>
            <div className="mt-2">
              <input
                onChange={changeHandler}
                type="text"
                name="lastName"
                id="last-name"
                value={input.lastName}
                className="block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
        </div>
        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
          <div className="sm:col-span-3">
            <label
              htmlFor="email"
              className="label block text-sm font-medium leading-6 text-gray-900"
            >
              Email*
            </label>
            <div className="mt-2">
              <input
                autoComplete="new-password"
                onChange={changeHandler}
                type="text"
                name="email"
                id="email"
                value={input.email}
                className="block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div className="sm:col-span-3">
            <label
              htmlFor="contact"
              className="label block text-sm font-medium leading-6 text-gray-900"
            >
              Contact
            </label>
            <div className="mt-2">
              <input
                onChange={changeHandler}
                type="text"
                name="contact"
                id="contact"
                className="block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
        </div>
        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
          <div className="sm:col-span-3">
            <label
              htmlFor="address"
              className="label block text-sm font-medium leading-6 text-gray-900"
            >
              Address
            </label>
            <div className="mt-2">
              <input
                // autoComplete="new-password"
                type="text"
                name="address"
                id="address"
                className="block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
        </div>
  

   
        <div className="mt-6 flex items-center justify-end gap-x-6">
          <Link to="/myprofile">
            <button
              type="button"
              className="text-sm  font-semibold leading-6 text-blue-500 border-2 p-2 px-5 rounded border-blue-500"
            >
              Cancel
            </button>
          </Link>
          <button
            onClick={(e) => handleEdit(e)}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2.5 px-4 -700 rounded"
          >
            Save Changes
          </button>
        </div>
      </div>
      {errorMessage && <p>{errorMessage}</p>}
    </div>
  );
};

export default EditProfile;
