import React from 'react'
import '../CSS/Loader.css'

const Loader = () => {
  return (
    <div>
        <div className="loader-main-container">
        
        <div className="loader-container">
      	  <div className="spinner"></div>
        </div>
    </div>
    </div>
  )
  
}

export default Loader;