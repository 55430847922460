import React from "react";
import "../../../CSS/WebsiteMiddle.css";
import SideBar from "../Components/SideBar";
const WebsiteMiddle = ({ children }) => {
  return (
    <div className="page_body">
      <div className="sidebar-h">
        <SideBar />
      </div>
      <div className="page_content">
        <div className="page_table">
          {children}
        </div>
      </div>
    </div>
  );
};
export default WebsiteMiddle;
