import React from "react";
import TableCheckNow from "./TableCheckNow";

const CheckNow = () => {
  return (
    <div>
      <TableCheckNow />
    </div>
  );
};

export default CheckNow;
