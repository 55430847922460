import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { useState } from "react";
import { toast } from "react-toastify";
import Loader from "../../../../Components/Loader";
import "../../../../CSS/EditModal.css";
import { base_url } from "../../../../Api/api.js";

const EditModal = ({ closeModal, id, fetchData }) => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [data, setData] = useState([]);
  const [input, setInput] = useState({ url: "", email: "", contactNumber: "" });

  useEffect(() => {
    fetchdata(id);
  }, []);

  const fetchdata = async (id) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${base_url}/url/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const { url, contactNumber, email } = response.data;
      console.log(data);
      setInput({
        url,
        contactNumber,
        email,
      });
    } catch (e) {
      console.log(e.response);
    }
  };

  const handleEdit = async (e, id) => {
    e.preventDefault();
    const number = parseInt(input.contactNumber);
    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      const response = await axios.patch(
        `${base_url}/url/${id}`,
        {
          email: input.email,
          contactNumber: number,
          url: input.url,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data;
      fetchData();

      if (data.urls) {
        setData(data.urls);
      } else {
        setErrorMessage(data.message);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      toast.success("URL updated successfully");
      setLoading(false);
      closeModal(false);
    }
  };

  const changeHandler = (e) => {
    const { name, value } = e.target;
    setInput({ ...input, [name]: value });
  };

  return (
    <div
      class="relative z-10"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
            <div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
              <div>
                <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left addnewmodal-main">
                  <div className="editmodaltop">
                    <h3 class="text-base font-semibold leading-6 text-gray-900">
                      {" "}
                      <div>Update URL</div>
                    </h3>
                    <div
                      class="mx-auto flex h-12 w-12 flex--0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-8 sm:w-8"
                      style={{
                        backgroundColor: "whitesmoke",
                        padding: 5,
                        color: "grey",
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        aria-hidden="true"
                        class="nx rz"
                        onClick={() => closeModal(false)}
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M6 18L18 6M6 6l12 12"
                        ></path>
                      </svg>
                    </div>
                  </div>
                  <div class="mt-2">
                    <div className="Email">URL*</div>
                    <input
                      className="einput"
                      type="text"
                      placeholder="Enter URL"
                      name="url"
                      value={input.url}
                      onChange={changeHandler}
                    />

                    <div className="Email">Email*</div>
                    <input
                      className="einput"
                      type="text"
                      placeholder="Enter Email"
                      name="email"
                      value={input.email}
                      onChange={changeHandler}
                    />

                    <label className="Password">Phone Number*</label>

                    <input
                      className="einput"
                      type="text"
                      placeholder="Enter Number"
                      name="contactNumber"
                      value={input.contactNumber}
                      onChange={changeHandler}
                    />

                    <div class="px-4 py-3 mt-4 sm:flex sm:flex-row-reverse sm:px-6">
                      <button
                        //   key={e.id}
                        type="button"
                        onClick={(e) => handleEdit(e, id)}
                        className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 sm:ml-3 sm:w-auto"
                      >
                        {loading ? <Loader /> : "Update"}
                      </button>

                      <Link to={"/website"}>
                        <button
                          type="button"
                          class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                          onClick={() => {
                            closeModal(false);
                          }}
                        >
                          Cancel
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {errorMessage && <p>{errorMessage}</p>}
    </div>
  );
};

export default EditModal;
