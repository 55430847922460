import React from 'react';
import '../../../CSS/SignUp.css';
import { Link } from 'react-router-dom';
import close from '../../../Assests/main/table/Close.svg';
import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Loader from '../../../Components/Loader.js';
import { toast } from 'react-toastify';
import {base_url} from "../../../Api/api.js"
import { FaRegEyeSlash } from "react-icons/fa";
import { MdOutlineVisibility } from "react-icons/md";

const SignUp = () => {
  const { register, handleSubmit, formState: { errors }, watch } = useForm();
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const [loading ,setLoading]=useState(false);
  const[passwordVisible,setPasswordVisible]=useState(false);

  const handleLogin = async (data) => {
    try {

       setLoading(true)
       const response = await axios.post(`${base_url}/auth/check-my-site/register`, {
        username: data.username,
        email: data.email,
        password: data.password,
      });

      const responseData = response.data;
      console.log(responseData);

      if (responseData.data.access_token) {
        localStorage.setItem('token', responseData.data.access_token);
        toast.success("User Created Successfully")
        navigate('/login');
      } else {
        setErrorMessage(responseData.message);
      }
    } catch (error) {
      console.error('Error:', error);
    } finally{
      setLoading(false)
    }
  };


  return (
    <div>

      <div className="container">
        <div className="container_first">
          <div className="welcome"> Welcome Back!👋</div>
          <Link to={'/'}>
            <div className="close_icon">
              <img src={close} alt="" />
            </div>
          </Link>
        </div>
        <div className='login_act'>Login to your Account</div>

        <form onSubmit={handleSubmit(handleLogin)}>
        <div className='login_inside'>

      
          <div className='Email'>Username*</div>
          <input
            className="input"
            type="text"
            placeholder='Enter Username'
            {...register('username', {
              required: 'Username is required',
            })}
          />
          {errors.username && <p p style={{color:"red"}}>{errors.username.message}</p>}

          <div className='Email'>Email Address*</div>
          <input
            className="input"
            type="text"
            placeholder='Enter Email'
            {...register('email', {
              required: 'Email is required',
              pattern: {
                value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: 'Please enter a valid email',
              },
            })}
          />
          {errors.email && <p p style={{color:"red"}}>{errors.email.message}</p>}

          <label className='Password'>Password*</label>
          <div className='signup-password-input'>
            <input 
            style={{marginBottom:"20px"}}
              className="sinput"
              type={passwordVisible? "text":"password"}
              placeholder='Enter Password'
              {...register('password', {
                required: 'Password is required',
                minLength: {
                  value: 8,
                  message: 'Password must be at least 8 characters long',
                },
              })}
            />
            
            {errors.password && <p p style={{color:"red"}}>{errors.password.message}</p>}
            <div className="icon2">
                 { passwordVisible? <MdOutlineVisibility onClick={()=>{setPasswordVisible(false)}}/> :<FaRegEyeSlash  onClick={()=>{setPasswordVisible(true)}}/>}
                </div>
          </div>

          {/* <label className='Password'>Confirm Password*</label> */}
          <div>
            {/* <input
              className="input"
              type="password"
              placeholder='Confirm Password'
              {...register('confirmPassword', {
                required: 'Confirm Password is required',
                validate: validateConfirmPassword,
              })}
            /> */}
            {errors.confirmPassword && <p p style={{color:"red"}}>{errors.confirmPassword.message}</p>}
          </div>
          </div>

          {/* <div className="forget_password">
            <div className='forget_password1'>Forget Password ?</div>
            <div>
              <input type="checkbox" />
              <label htmlFor="vehicle2">Remember me</label>
            </div>
          </div> */}
          <div>
            <button className='login_btn' type="submit"> {loading? <Loader/> :"Sign Up"}</button>
          </div>
          <div className='last'>Already have an account?<Link to='/login'><span className='span'>Sign In</span></Link></div>
          {errorMessage && <p p style={{color:"red"}}>{errorMessage}</p>}
        </form>
      </div>
      
    </div>
  );
};

export default SignUp;
