import React from "react";
import { Route, Navigate } from "react-router-dom";
import WebsiteMiddle from "../PageWrapper/Dashboard/Websites/WebsiteMiddle";

const ProtectedRoute = ({ children }) => {
  const isLoggedIn = !!localStorage.getItem("token");

  if (isLoggedIn) {
    return (
      <>
        <WebsiteMiddle> {children} </WebsiteMiddle>
      </>
    );
  } else {
    return <Navigate to="/login" replace />;
  }
};

export default ProtectedRoute;
