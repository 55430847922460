import React from "react";
import { useForm } from "react-hook-form";
import "../../../../CSS/Modals.css";
import { Link } from "react-router-dom";
import "../../../../CSS/AddnewModal.css";
import axios from "axios";
import { useState } from "react";
import Loader from "../../../../Components/Loader";
import { toast } from "react-toastify";
import { base_url } from "../../../../Api/api.js";

const AddnewModal = ({ closeModal, fetchData }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm(); // Initialize the useForm hook
  const [loading, setLoading] = useState(false);
  const [sloading, setSloading] = useState(false);

  const savehandleLogin = async (data) => {
    console.log(data);
    try {
      setSloading(true);
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${base_url}/url`,
        {
          url: data.url,
          email: data.email,
          contactNumber: parseInt(data.contactNumber),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseData = response.data;
      console.log(responseData);

      toast.success("URL added Successfully");
      closeModal(false);
      fetchData();
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setSloading(false);
    }
  };

  const saveandaddhandleLogin = async (data) => {
    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${base_url}/url`,
        {
          url: data.url,
          email: data.email,
          contactNumber: parseInt(data.contactNumber),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const responseData = response.data;
      console.log(responseData);
      fetchData();
      reset();
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className="relative z-10"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
              <div>
                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left addnewmodal-main">
                  <div className="addnewtop">
                    <h3
                      className="text-base font-semibold leading-6 text-gray-900"
                      id="modal-title"
                    >
                      Add New Website
                    </h3>
                    <div
                      class="mx-auto flex h-12 w-12 flex--0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-8 sm:w-8"
                      style={{
                        backgroundColor: "whitesmoke",
                        padding: 5,
                        color: "grey",
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        aria-hidden="true"
                        class="nx rz"
                        onClick={() => closeModal(false)}
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M6 18L18 6M6 6l12 12"
                        ></path>
                      </svg>
                    </div>
                  </div>

                  <div className="mt-2">
                    <form
                      onSubmit={handleSubmit(
                        savehandleLogin,
                        saveandaddhandleLogin
                      )}
                    >
                      <div className="anUrl">URL*</div>
                      <input
                        className="aninput"
                        type="text"
                        placeholder="Enter URL"
                        name="url"
                        {...register("url", {
                          required: "URL is required",
                          pattern: {
                            value:
                              /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/,
                              message:"Please enter a vaid URL"
                          },
                        })}
                      />
                      {errors.url && (
                        <p style={{ color: "red" }}>{errors.url.message}</p>
                      )}

                      <div className="anEmail">Email*</div>
                      <input
                        className="aninput"
                        type="text"
                        placeholder="Enter Email"
                        name="email"
                        {...register("email", {
                          required: "Email is required",
                          pattern: {
                            value:
                              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: "Please Enter a valid email",
                          },
                        })}
                      />
                      {errors.email && (
                        <p style={{ color: "red" }}>{errors.email.message}</p>
                      )}

                      <label className="anPassword">Phone Number*</label>

                      <input
                        className="aninput"
                        type="number"
                        placeholder="Enter Number"
                        name="contactNumber"
                        {...register("contactNumber", {
                          required: true,
                        })}
                      />
                      {errors.contactNumber && (
                        <p style={{ color: "red" }}>This field is required</p>
                      )}

                      <div className="px-4 py-3 mt-4 sm:flex sm:flex-row-reverse sm:px-6 ">
                        <button
                          type="submit"
                          onClick={handleSubmit(saveandaddhandleLogin)}
                          className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto"
                        >
                          {loading ? <Loader /> : "Save & Add"}
                        </button>
                        <button
                          type="submit"
                          onClick={handleSubmit(savehandleLogin)}
                          className="save-button inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto "
                        >
                          {sloading ? <Loader /> : "Save"}
                        </button>
                        <Link to={"/website"}>
                          <button
                            type="button"
                            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                            onClick={() => {
                              closeModal(false);
                            }}
                          >
                            Cancel
                          </button>
                        </Link>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddnewModal;
